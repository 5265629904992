<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :sst="true"
        stripe
        :data="brokers"
        @sort="handleSort"
        @search="search"
        @selected="
            $router.push({ name: 'broker', params: { pk: $event.email } })
        "
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{ itemsPerPage }}</span>
                    <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4 text-secondary"
                    />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th sort-key="company_name">Company Name</vs-th>
            <vs-th sort-key="introducer_agreement_status">Agreement</vs-th>
            <vs-th sort-key="direct_debit_status">Direct Debit</vs-th>
            <vs-th sort-key="primis_id">Primis ID</vs-th>
            <vs-th sort-key="plan">Plan</vs-th>
            <vs-th sort-key="active">Status</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr
                    :data="broker"
                    :key="index"
                    v-for="(broker, index) in data"
                >
                    <vs-td>{{ broker.company_name }}</vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="
                                broker.introducer_agreement_status
                                    ? 'CheckIcon'
                                    : 'XIcon'
                            "
                            svg-classes="text-primary"
                        />
                    </vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="
                                broker.direct_debit_status
                                    ? 'CheckIcon'
                                    : 'XIcon'
                            "
                            svg-classes="text-primary"
                        />
                    </vs-td>

                    <vs-td>
                        {{ broker.primis_id }}
                    </vs-td>
                    <vs-td>
                        <span v-if="broker.plan">{{ broker.plan.plan }}</span>
                    </vs-td>
                    <vs-td>
                        <vs-chip
                            v-if="broker.active"
                            color="success"
                            class="ml-auto"
                            >Account Active</vs-chip
                        >
                        <vs-chip
                            v-if="!broker.active"
                            color="secondary"
                            class="ml-auto"
                            >Account Disbaled</vs-chip
                        >
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'BrokersAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage'],
    computed: {
        brokers() {
            return this.data.data
        },
    },
    data() {
        return {
            selected: null,
            searchQuery: '',
            status: 'All',
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        handleSort(key, value) {
            this.$emit('sort', [key, value])
        },
    },
}
</script>
